import { Formik } from "formik";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import ReactQuill from "react-quill";
import api from "../../adaptors/xhr/api";
import LinkIcon from '../../assets/icons/link.svg';
import Button from "../Button/Button";
import FormInputLarge from '../FormInputLarge/FormInputLarge';
import { commentSchema, validateComment, calculateWords } from "../../pages/Community/ducks/validations";

function EditCommentModal({ linkHandler, quillRef, editCommentId, editComment, onUpdateComment, ...props}) {

  const submitHandle = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    const { comment } = values;
    console.log(comment);
    try {
      // update comment
      const response = await api.Forum.updateComment(editCommentId, comment);
      if (response.status === 201) {
        // succesfully created post
        // setEmailSent(values.email);
        // close modal
        onUpdateComment(response.data);
      }
      
      resetForm();
      props.onHide();
    }
    catch (error) {
      // read server validation errors
      // if (error.response.data?.non_field_errors) setNonFieldErrors(error.response.data?.non_field_errors);
      console.error(error);
    }
    setSubmitting(false);
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="community-guidelines-modal edit-comment-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">

        </Modal.Title>
      </Modal.Header>
      <Formik
        // validationSchema={commentSchema}
        validate={validateComment}
        onSubmit={submitHandle}
        initialValues={{
          comment: editComment,
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <Form className="form" noValidate onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="d-flex justify-content-start align-items-start title-section">
                <h4>Edit Comment</h4>
              </div>

              <FormInputLarge
                name="comment"
                label="Edit details"
                placeholder="Type your comment..."
                value={values.comment}
                onChange={handleChange}
                onBlur={handleBlur}
                // isValid={touched.comment && !errors.comment}
                isInvalid={touched.comment && !!errors.comment}
                feedback={errors.comment}
                help={`${calculateWords(values.comment)}/200 words`}
                formType={"Post"}
              />

            </Modal.Body>
            <Modal.Footer>
              <div className="row">
                <div className="col px-0 d-flex align-items-between justify-content-center">
                  <Button size="lg" onClick={props.onHide} text='Cancel' className="mr-2" />
                  <Button type="primary" size="lg" text={isSubmitting ? "Saving..." : "Save"} submit />
                </div>
              </div>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default EditCommentModal;