import { axiosInstance, axiosAuthenticate } from './xhrConfig';


const Content = {

  homepage: () =>
    axiosInstance.get(`/api/content/home/`),
  gettingStarted: () =>
    axiosInstance.get(`/api/content/getting-started/`),
  about: () =>
    axiosInstance.get(`/api/content/about/`),
  navigatingTheWebsite: () =>
    axiosInstance.get(`api/content/navigating-the-website/`),
};

export default Content;
