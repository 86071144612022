import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Navbar from "../../components/Navbar";
import Breadcrumb from "../../components/Breadcrumb";
import PageHeader from "../../components/PageHeader";
import { addModule } from "./ducks/actions";
import TopNavbar from "../../components/TopNavbar";
import PageFooter from "../../components/PageFooter/PageFooter";
import GettingStartedFooter from "../../components/GettingStartedFooter";
import { routes } from "../../routes";
import { addGettingStartedContent } from "../Common/ducks/actions";
import { getGettingStartedContent } from "../Common/ducks/selectors";
import NavbarGettingStarted from "../../components/NavbarGettingStarted";
import PageContent from "../../components/PageContent";
import SectionCard from "../../components/SectionCard";
import { FaqSection } from "../Faq/Faq";
import {
  fetchFaqSections,
  fetchFaqEntriesBySection,
} from "../../adaptors/xhr/faqService";
import { addSection, addEntry, addSectionEntryRef } from "../Faq/ducks/actions";
import { getEntries, getSections } from "../Faq/ducks/selectors";
import api from "../../adaptors/xhr/api";
import ContactModal from "../../components/ContactModal";
import { Link } from "react-router-dom";

function GettingStartedModuleStructure(props) {
  console.log(props);
  const [contactShow, setContactShow] = useState(false);

  const handleContactClick = () => {
    setContactShow(true);
  };

  /** Fetches Entries for a section from api */
  const addEntries = async (sectionId) => {
    try {
      const entriesApiResponse = await fetchFaqEntriesBySection(sectionId);
      entriesApiResponse.data.results.map((entry) => {
        props.addEntry(entry, sectionId);
        props.addSectionEntryRef(sectionId, entry.id);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    try {
      // fetch data from api if store data empty
      if (!props.sections.length) {
        const sectionsApiResponse = await fetchFaqSections();
        sectionsApiResponse.data.results?.map((section) => {
          props.addSection(section);
          // Add entries
          addEntries(section.id);
        });
      }

      const gettingStartedResponse = await api.Content.gettingStarted();
      if (gettingStartedResponse.data)
        props.addGettingStartedContent(gettingStartedResponse.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <TopNavbar />
      <Navbar />
      <Breadcrumb
        breadcrumbs={["Home", "Getting Started with TRP", "FAQ"]}
        links={[
          "/",
          routes.gettingStartedNavigatingTheWebsite,
          routes.gettingStartedFaq,
        ]}
        className="getting-started-breadcrumbs"
      />
      <PageHeader
        heading={"Getting Started with TRP"}
        textAlign={"center"}
      ></PageHeader>
      <NavbarGettingStarted style={{top: '132px'}} />
      {/* ..... */}

      <div
        className="col-xs-12 col-md-8 mx-md-auto text-center"
        style={{ marginTop: "3rem" }}
      >
        <h3>FAQs</h3>
        <p className="p3">
          Get answer for most common questions. If you need any further help or
          have suggestions, &nbsp;
          <a href="#" onClick={handleContactClick}>contact us</a>.
        </p>
      </div>

      <PageContent>
        <div className="row">
          <div className="col-xs-12 col-md-8 mx-md-auto">
            {props.sections?.map((section) => {
              return (
                <FaqSection
                  key={`faq-section-${section.id}`}
                  section={section}
                  entries={props.entries}
                />
              );
            })}
            <SectionCard />
          </div>
        </div>
        <ContactModal show={contactShow} onHide={() => setContactShow(false)} />
      </PageContent>

      <GettingStartedFooter
        quick_start_guide={props.gettingStartedContent?.quick_start_guide}
        adaptation_checklist={props.gettingStartedContent?.adaptation_checklist}
        handleContactClick={handleContactClick}
      />
      <PageFooter />
    </>
  );
}

const mapStateToProps = (state) => {
  const gettingStartedContent = getGettingStartedContent(state);
  const sections = getSections(state);
  const entries = getEntries(state);
  return { gettingStartedContent, sections, entries };
};

export default connect(mapStateToProps, {
  addModule,
  addGettingStartedContent,
  addSection,
  addEntry,
  addSectionEntryRef,
})(GettingStartedModuleStructure);
