// import axios from 'axios';
import fileDownload from 'js-file-download';
import { string } from 'yup';
import { HOST, axiosInstance } from './xhrConfig';

export function getTrainingModuleCategories() {
  return axiosInstance.get(`api/training/modulecategory/`)
}

export function getTrainingModules() {
  return axiosInstance.get(`api/training/module/`)
}

export function getTrainingModulesByCategory(catId) {
  return axiosInstance.get(`api/training/module/?category=${catId}`)
}

export function getModuleDetailService(id) {
  return axiosInstance.get(`api/training/module/${id}`)
}

export function getModuleDetailServiceBySlug(slug) {
  return axiosInstance.get(`api/training/module/${slug}`)
}

export function getTrainingObjectives(moduleId) {
  return axiosInstance.get(`api/training/learningobjective/?module=${moduleId}`)
}

export function getModuleSessionPlan(moduleId) {
  return axiosInstance.get(`api/training/modulesessionplanner/?module=${moduleId}`)
}

export function getPresentationSlide(moduleId) {
  return axiosInstance.get(`api/training/presentatonslide/?module=${moduleId}`)
}

export function getEvaluationTools(moduleId) {
  return axiosInstance.get(`api/training/evaluationtool/?module=${moduleId}`)
}

export function getHandouts(moduleId) {
  return axiosInstance.get(`api/training/handout/?module=${moduleId}`)
}

export function getReferences(moduleId) {
  return axiosInstance.get(`api/training/reference/?module=${moduleId}`)
}

export function download(url, filename) {
  console.log('filename', filename);
  axiosInstance.get(url, {
    responseType: 'blob',
  }).then(res => {
    fileDownload(res.data, filename);
  });
}

export function getDownloads(moduleId) {
  console.log("moduleId", moduleId)
  return axiosInstance.get(`api/training/module/${moduleId}/downloads/`)
}

export function downloadModule(moduleId,  moduleName) {
  axiosInstance.get(`api/training/module/${moduleId}/download-all-files/`, {
    responseType: 'blob',
  }).then(res => {
    fileDownload(res.data, `${moduleName}.zip`);
  });
}

export function downloadPresentationSlides(moduleId) {
  axiosInstance.get(`api/training/module/${moduleId}/download-presentation-slides/`, {
    responseType: 'blob',
  }).then(res => {
    fileDownload(res.data, `Presentation Slides ${moduleId}.zip`);
  });
}

export function downloadFrenchMaterials() {
  axiosInstance.get(`api/content/french-materials/download-all/`, {
    responseType: 'blob',
  }).then(res => {
    fileDownload(res.data, `French Materials.zip`);
  });
}

export function downloadSpanishMaterials() {
  axiosInstance.get(`api/content/spanish-materials/download-all/`, {
    responseType: 'blob',
  }).then(res => {
    fileDownload(res.data, `Spanish Materials.zip`);
  });
}


export const Training = {
  additionalTrainingTools: () =>
    axiosInstance.get(`api/training/additional-training-tools/`),

  frenchMaterials: () =>
    axiosInstance.get(`api/content/french-materials/`),

  spanishMaterials: () =>
    axiosInstance.get(`api/content/spanish-materials/`),
};

export function siteSearch(keyword) {
  return axiosInstance.get(`api/training/module-resource/search/?keyword=${keyword}`)
}

export function getVideosByModuleId(moduleId) {
  return axiosInstance.get(`/api/training/videos/?module=${moduleId}`)
}

export function getCoverVideoByModuleId(moduleId) {
  return axiosInstance.get(`/api/training/modules/${moduleId}/video-cover/`)
}