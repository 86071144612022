import React, { useEffect, useState } from 'react';
import {
  useParams,
  withRouter
} from "react-router-dom";
import { RWebShare as WebShare } from 'react-web-share' ;
import { getLoginState } from '../Common/ducks/selectors';
import dateFromString from '../../utils/dateFromString';
import { connect, useSelector } from "react-redux";
import listSvg from '../../assets/icons/list.svg';
import BookmarkIconFill from '../../assets/icons/bookmark-fill.svg';
import questionSvg from '../../assets/icons/question.svg';
import TopNavbar from '../../components/TopNavbar';
import Navbar from '../../components/Navbar';
import Breadcrumb from '../../components/Breadcrumb';
import PageHeader from '../../components/PageHeader';
import NavbarTabs from '../../components/NavbarTabs';
import PageContent from '../../components/PageContent';
import FileAlert from '../../components/FileAlert';
import NumberedList from '../../components/NumberedList';
import { getModuleDetailService, getModuleDetailServiceBySlug, getTrainingObjectives, getCoverVideoByModuleId } from '../../adaptors/xhr/trainingService';
import { findDiscussion } from '../../adaptors/xhr/communityService';
import {
  addModule,
  addModuleLearningObjectives,
  setLearningObjectivesInModule,
  addModuleDiscussionId,
  addTool,
  addBookmarkFolder,
  addDefaultBookmarkFolder,
  addBookmarkContext,
  addBookmarkItem,
  removeBookmarkItem } from './ducks/actions';
import { addPost, addTopic} from '../Community/ducks/actions'
import {
  getModuleById,
  getModuleLearningObjectives,
  getModuleLearningObjectivesList,
  getLearningObjectives,
  getTools,
  getBookmarksFolders,
  getDefaultBookmarkFolderId,
  getBookmarkItems,
  getBookmarkItemsList,
  getBookmarkContextState, } from './ducks/selectors';
import ButtonDetailed from '../../components/ButtonDetailed/ButtonDetailed';
import KeyValue from '../../components/KeyValue';
import Button from '../../components/Button';
import ViewCount from '../../components/ViewCount';
import BookmarkCount from '../../components/BookmarkCount';
import BookmarkModal from '../../components/BookmarkModal';
import BookmarkButtonRegular from '../../components/BookmarkButtonRegular';
import PageFooter from '../../components/PageFooter/PageFooter';
import AdditionalTrainingTools from '../../components/AdditionalTrainingTools';
import { routes } from '../../routes';
import api from '../../adaptors/xhr/api';
import LoginModal from '../../components/LoginModal';
import FilePreviewModal from '../../components/FilePreviewModal';
import VideoPreview from '../../components/VideoPreview/VideoPreview';



function Overview(props) {
  const [ fetchObjectives, setFetchObjectives ] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [ id, setId ] = useState(null);
  const [ loginShow, setLoginShow ] = useState(false);
  const [additionalTrainingShow, setAdditionalTrainingShow] = useState(false);
  const [ previewModalShow, setPreviewModalShow ] = useState(false);
  const [ previewFileObj, setPreviewFileObj ] = useState(null);
  const [coverVideo, setCoverVideo ] = useState(null);

  let { slug } = useParams();
  const module = useSelector((state) => getModuleById(state, id));
  const fileUrl = module.preview_file? module.preview_file: module.facilitators_guide_file;
  const file_type = module.preview_file? "PDF Document": module.file_type;
  const sourceFile = module.facilitators_guide_file;
  const sourceFileType = module.file_type;
  const fetchAdditionalTrainingTools = async () => {
    const response = await api.Training.additionalTrainingTools();
    response.data?.results?.map(file => props.addTool(file))
  }

  const fetchCoverVideo = async () => {
    try {
      const response = await getCoverVideoByModuleId(module.id);
      if (response.status == 200) {
        setCoverVideo(response.data);
      }
    } catch (e) {
      console.log("No Cover Video found");
    }
  }

  const fetchBookmarks = async () => {
    try {
      const response = await api.Bookmarks.folders();
      response.data?.map(bookmark => {
        props.addBookmarkFolder(bookmark);
        if (bookmark.is_default == true) props.addDefaultBookmarkFolder(bookmark);
        // fetch modules
        bookmark.modules?.map(item => {
          props.addBookmarkItem(bookmark.id, `module-${item.id}`, item, "modules");
        })

        // fetch module_session_plans
        bookmark.module_session_plans?.map(item => {
          props.addBookmarkItem(bookmark.id, `module_session_plan-${item.module.id}-${item.id}`, item, "module_session_plans");
        })

        // fetch presentation_slides
        bookmark.presentation_slides?.map(item => {
          props.addBookmarkItem(bookmark.id, `presentation_slides-${item.module.id}-${item.id}`, item, "presentation_slides");
        })

        // fetch evaluation_tools
        bookmark.evaluation_tools?.map(item => {
          props.addBookmarkItem(bookmark.id, `evaluation_tools-${item.module.id}-${item.id}`, item, "evaluation_tools");
        })

        // fetch references
        bookmark.references?.map(item => {
          props.addBookmarkItem(bookmark.id, `references-${item.module.id}-${item.id}`, item, "references");
        })

        // fetch handouts
        bookmark.handouts?.map(item => {
          props.addBookmarkItem(bookmark.id, `handouts-${item.module.id}-${item.id}`, item, "handouts");
        })
      })
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {

    getModuleDetailServiceBySlug(slug)
    .then(response => {
      console.log('Success:', response.data);
      setId(response.data.id);
      props.addModule(response.data, response.data.category?.id);
      // set page title
      document.title=response.data.name;
    })
    .catch((error) => {
      console.error('Error:', error);
    })

    fetchBookmarks();
    fetchCoverVideo();
    fetchAdditionalTrainingTools();
  }, [slug]);

  useEffect(() => {
    // if (module && !fetchObjectives) {
      getTrainingObjectives(id)
      .then((objectives) => {
        setFetchObjectives(true);
        objectives.data.results.map((data) => {
          props.addModuleLearningObjectives(data, id)
          props.setLearningObjectivesInModule(data.id, id)
        })
      })
      .catch((error) => {
        console.log(error)
      })
    // }
  }, [id])

  useEffect(() => {
    findDiscussion(module.name)
    .then((values) => {
      console.log(values)
        values.data?.results?.map((post) => {
            props.addPost(post)
            props.addModuleDiscussionId(post.id, id)
        })
    }).catch((err) => console.log(err))
}, [slug]);

const previewFile = (fileObj) => {
  setPreviewFileObj(fileObj)
  setPreviewModalShow(true);
}

const addBookmark = () => {
  if (props.currentUser.email) {
    // console.log(section, fileId, fileObj);
    props.addBookmarkContext('module', id, module);
    setModalShow(true);
  }
  else
    setLoginShow(true);
}
console.log(module);

const removeBookmark = async () => {
  // props.removeBookmark(props.sectionId, props.fileId, props.fileObj)
  // console.log(">>section", section)
  // console.log(">>fileId", fileId)
  // console.log(">>fileObj", fileObj)
  console.log("props.bookmarkItems", props.bookmarkItems)
  const bookmarkItem = await props.bookmarkItems.find(item => item.moduleId == id)
  const response = await api.Bookmarks.removebookmarkedItem(
    bookmarkItem.folderId,
    bookmarkItem.sessionType,
    bookmarkItem.pk
  )
  if (response.status === 200) {
    props.removeBookmarkItem(bookmarkItem.folderId, bookmarkItem.id, bookmarkItem);
    setModalShow(false);
  }
}

  const [bcHt, setBcHt] = useState(0);

  const setHt = (value) => {
    setBcHt(value);
  }

  return (
  <>
    <TopNavbar />
    <Navbar nosticky />
    <Breadcrumb
      breadcrumbs={["Home", "Training Modules", module.name]}
      links={["/","/training/",`/training/${module.slug}/`]}
      setHt={setHt}
    />
    <PageHeader heading={ module.name }/>
    {
      module.relatedDiscussionsId && module.relatedDiscussionsId.length ?
        <NavbarTabs discussionsNumbers = {module.relatedDiscussionsId.length} bcHt={bcHt} />
        : <NavbarTabs discussionsNumbers = {[0]} bcHt={bcHt} />}

    <PageContent>
      <div className="row">
        <div className="col-xs-12 col-lg-7">
          <div className="training-overview-left">
            <div className='' style={{paddingBottom: '34px'}}>
              <h4>Purpose</h4>
              <p className="p3" dangerouslySetInnerHTML={{ __html: module.purpose }} />
            </div>
            <div style={{paddingBottom: '12px'}}>
              <h4>{ module.name } Facilitators Guide</h4>
              <p className="p3" dangerouslySetInnerHTML={{ __html: module.facilitators_guide }} />
            </div>

            <FileAlert
              name={`${module.name} Facilitators Guide`}
              fileType={file_type}
              fileSize={module.facilitators_guide_file_size}
              fileUrl={fileUrl}
              previewFile={previewFile}
              sourceFile={sourceFile}
              sourceFileType={sourceFileType}
            />

            <h4>Learning Objectives</h4>
            <p className="p3">
              By the end of the training, as appropriate to their
              scope of practice and job description, trainees should be able to:
            </p>
            <NumberedList list={props.learningObjectives.filter(lobj => lobj.moduleId === id) || []}/>
          </div>
        </div>

        <div className="col-xs-12 col-lg-4 offset-lg-1">
            <ButtonDetailed
              type="primary"
              className="mb-3"
              onClick={() => props.history.push('sessions')}
            />
            <ButtonDetailed
              type="outline"
              text="Additional Training Tools"
              subtext="General Facilitator’s Guide, case studies and more."
              className="mb-3"
              onClick={() => setAdditionalTrainingShow(true)}
              icon={listSvg}
            />

            <ButtonDetailed
              // type="outline"
              text="Getting started with modules"
              subtext="View short guide about module structure"
              className="mb-5"
              onClick={() => props.history.push(routes.gettingStartedModuleStructure)}
              icon={questionSvg}
            />



            {coverVideo?
            <>
            <div className="d-flex justify-content-between py-3">
              <div style={{fontSize: "16px", fontWeight: 600}}> Related Media</div>
              <div style={{fontSize: "13px", fontWeight: 500, color: "#0158E2"}}>
                 {/* View All Videos */}
                 <Button size="md"  text="View All Videos" minWidth className="d-none d-sm-block" onClick={() => props.history.push('sessions#videos')}/>
                 </div>

            </div>
            <VideoPreview videoData={coverVideo}/>
            <hr/>
            </>
            : null}


            <div className="">
            <KeyValue
                keyName="Category"
                value={module.category?.name}
              />
              <KeyValue
                keyName="Language"
                value={module.language}
              />
              <KeyValue
                keyName="Publication Date"
                value={dateFromString(module.created_at)}
              />
              <KeyValue
                keyName="Updated on"
                value={dateFromString(module.updated_at)}
              />
            </div>

            <div className="row my-4">
              <div className="col-6 pr-1">
              <WebShare
                data={{title: document.title,
                url: window.location.href,
                text: document.title
                }}
                onClick={() => console.info("share successful!")}
              >
                <Button
                  type="outline"
                  size="md"
                  text="Share"
                  // onClick={share}
                  onClick={console.log}
                />
              </WebShare>
              </div>
              <div className="col-6 pl-1">
                <BookmarkButtonRegular
                  type="outline"
                  size="md"
                  text="Bookmark"
                  icon={BookmarkIconFill}
                  bookmarked={props.bookmarkItemsIds?.includes(`module-${id}`)}
                  addBookmark={addBookmark}
                  removeBookmark={removeBookmark}
                />
              </div>
              {/* <div class="sharethis-inline-share-buttons d-none"></div> */}
            </div>

            {/* <ViewCount count={module.view_count}/> */}
            {/* <BookmarkCount/> */}
            <BookmarkModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              folders={props.bookmarksFolders}
              defaultFolderId={props.bookmarkFolderDefaultId}
              bookmarkContext={props.bookmarkContext}
              // bookmarkInfo={{sessionType: bookmarksessionType, itemId: bookmarkItemId}}
              onCreatedFolder={props.addBookmarkFolder}
              bookmarkSuccess={props.addBookmarkItem} // action
              type="module"
            />
            <AdditionalTrainingTools
              files={props.trainingTools}
              show={additionalTrainingShow}
              onHide={() => setAdditionalTrainingShow(false)}
              previewFile={previewFile}
            />
        </div>



      </div>
    </PageContent>
    <PageFooter/>
    <LoginModal
      show={loginShow}
      onHide={() => setLoginShow(false)}
    />
    <FilePreviewModal
      show={previewModalShow}
      onHide={() => setPreviewModalShow(false)}
      fileObj={previewFileObj}
    />
  </>
  );
}

const mapStateToProps = state => {
  const bookmarkItems = getBookmarkItems(state);
  const moduleLearningObjective = getModuleLearningObjectives(state)
  const moduleLearningObjectivesList = getModuleLearningObjectivesList(state)
  const learningObjectives= getLearningObjectives(state)
  const trainingTools = getTools(state)
  const bookmarksFolders = getBookmarksFolders(state);
  const bookmarkFolderDefaultId = getDefaultBookmarkFolderId(state);
  const bookmarks = getBookmarkItems(state);
  const bookmarkItemsIds = getBookmarkItemsList(state);
  const bookmarkContext = getBookmarkContextState(state);
  const currentUser = getLoginState(state);
  return {
    moduleLearningObjective,
    moduleLearningObjectivesList,
    learningObjectives,
    trainingTools,
    currentUser,
    bookmarksFolders,
    bookmarkFolderDefaultId,
    bookmarks,
    bookmarkItems,
    bookmarkItemsIds,
    bookmarkContext,
  };
};

export default connect(
  mapStateToProps,
  { addModule,
    addModuleLearningObjectives,
    setLearningObjectivesInModule,
    addTool,
    addModuleDiscussionId,
    addPost,
    addTopic,
    addBookmarkFolder,
    addDefaultBookmarkFolder,
    addBookmarkContext,
    addBookmarkItem,
    removeBookmarkItem
  }
)(withRouter(Overview));
