import { combineReducers } from "redux";
import { authReducer } from "./auth";
import newsReducer from './news';
import { contentReducer } from './content';
import searchReducer from './search';


export default combineReducers( {
  auth: authReducer,
  news: newsReducer,
  content: contentReducer,
  search: searchReducer,
});