import React, { useEffect, useState } from 'react';
import {
  useHistory,
  Link,
  Redirect,
  withRouter
} from "react-router-dom";
import { connect } from "react-redux";
import { Formik } from 'formik';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Navbar from '../../components/Navbar';
import TopNavbar from '../../components/TopNavbar';
import Breadcrumb from '../../components/Breadcrumb';
import PageContent from '../../components/PageContent';

import PageFooter from '../../components/PageFooter/PageFooter';
import Button from '../../components/Button';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import { loginUser } from './ducks/actions';
import { getLoginState } from './ducks/selectors';
import { getProfileFetch } from './ducks/operations';
import { routes } from '../../routes';
import UserThumb from '../../components/UserThumb';
import { profileSchema } from './ducks/validations';
import ProfilePhotoPreview from '../../components/ProfilePhotoPreview';
import api from '../../adaptors/xhr/api';
import ToggleSwitch from '../../components/ToggleSwitch';


function Settings(props) {

  const history = useHistory();

  const [ nonFieldErrors, setNonFieldErrors ] = useState([]);
  const [emailCommunityResponse, setEmailCommunityResponse] = useState(props.currentUser?.email_community_responses);
  const [emailNewModule, setEmailNewModule] = useState(props.currentUser?.email_new_module);
  const [emailOccasionalCommunication, setEmailOccasionalCommunication] = useState(props.currentUser?.email_occassional_updates);
  const [savedChanges, setSavedChanges] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [navHeight, setNavHeight] = useState(0);

  
  useEffect(() => {
   setEmailCommunityResponse(props.currentUser?.email_community_responses)
    setEmailNewModule(props.currentUser?.email_new_module)
    setEmailOccasionalCommunication(props.currentUser?.email_occassional_updates)
  }, [])
  /** Hooks */
  const inputReference = React.createRef();

  function saveSuccess() {
    setSavedChanges(true);
    setShowMessage(true);
    setTimeout(
      () => setShowMessage(false), 
      3000
    );
  }

  // useEffect(() => {
  //   props.getProfileFetch();
  // }, []);

  /** Handles */
  // `Formik` form submit handle
  const submitHandle = async (values, {setSubmitting, resetForm}) => {
    setSubmitting(true);
    const { firstName, lastName, profilePhoto, timezone, bio, linkedinUrl } = values;
    console.log("VALUES", values)
    try {
      // try login user
      const response = await api.Auth.updateUser(firstName, lastName, profilePhoto, timezone, bio, linkedinUrl);
      if (response.status === 200) {
        // login success
        console.log(response.data)
        props.loginUser(response.data)
        saveSuccess();
      }
    }
    catch (error) {
      // read server validation errors
      if (error.response.data?.non_field_errors) setNonFieldErrors(error.response.data?.non_field_errors);
      console.error(error);
    }
    setSubmitting(false);
  }

  const cancelEdit = (resetForm) => {
    resetForm();
    props.history.push(`/community/user/${props.currentUser.pk}`);
  }

  const handleChangeEmailCommunityResponse = async (event) => {
    const response = await api.Auth.updateUserPreference({emailCommunityResponse: event.target.checked})
    if(response.status === 200){
      props.loginUser(response.data)
      setEmailCommunityResponse(!emailCommunityResponse)
    }
    
  } 

  const handleChangeEmailModuleChange = async (event) => {
    const response =await api.Auth.updateUserPreference({emailNewModule: event.target.checked})
    if(response.status === 200){
      props.loginUser(response.data)
      setEmailNewModule(!emailNewModule)
    }
    
  }

  const handleChangeEmailOccasionalCommunication = async (event) => {
    const response =await api.Auth.updateUserPreference({emailOccasionalCommunication: event.target.checked})
    if(response.status === 200){
      props.loginUser(response.data)
    setEmailOccasionalCommunication(!emailOccasionalCommunication)
  }
  }


  /** Render */

  if (!props.currentUser.email) {
    return (
      <Redirect to={routes.login} />
    )
  }

  const setHt = (value) => {
    setNavHeight(value);
  }

  return (
    <>
      <TopNavbar/>
      <Navbar currentUser={props.currentUser} setHt={setHt} />
      <Breadcrumb breadcrumbs={["Home", "My Account", "Settings"]} navHeight={navHeight} />
      <PageContent>
        <div className="profile-settings-page row mt-2">
          <div className="col-12 col-md-6 mx-auto">
            <h3 className="mb-3">Profile Settings</h3>
            
            <Formik
              validationSchema={profileSchema}
              onSubmit={submitHandle}
              initialValues={{
                firstName: props.currentUser.first_name,
                lastName: props.currentUser.last_name,
                timezone: props.currentUser.timezone,
                bio: props.currentUser.bio,
                linkedinUrl: props.currentUser.linkedin_url,
                profilePhoto: null,
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                resetForm
              }) => (
                <Form className="form py-4" noValidate onSubmit={handleSubmit}>
                  <div className="profile-photo-section d-flex justify-content-start align-items-center mb-4">
                    {values.profilePhoto == null
                      ? <UserThumb 
                          width="72"
                          height="72"
                          thumbnail={props.currentUser.profile_picture}
                          firstName={props.currentUser.first_name}
                          lastName={props.currentUser.last_name}
                        />
                      : null
                    }
                    {values.profilePhoto == ""
                      ? <UserThumb 
                          width="72"
                          height="72"
                          // thumbnail={props.currentUser.profile_picture}
                          firstName={props.currentUser.first_name}
                          lastName={props.currentUser.last_name}
                        />
                      : null
                    }
                    {values.profilePhoto ? <ProfilePhotoPreview width="72" height="72"  file={values.profilePhoto} /> : null}
                    <div class="d-flex flex-column">
                      <div class="d-flex justify-content-start align-items-center py-1">
                        <input
                          name="profilePhoto"
                          type="file"
                          hidden ref={inputReference}
                          onChange={(event) => setFieldValue("profilePhoto", event.currentTarget.files[0])}
                          accept="image/png, image/jpeg"
                        />
                        <Button
                          type="outline"
                          size="md"
                          text="Upload New Image"
                          minWidth className="ml-4"
                          onClick={() => inputReference.current.click()}
                        />
                        <Button size="md" text="Remove " minWidth className="ml-3" onClick={() => setFieldValue("profilePhoto", "")} />
                      </div>
                      <p class="profile-photo-help p4 px-4 mb-0 py-1">Please ensure your photo is in a square aspect ratio.</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <FormInput
                        type="text"
                        label="First Name"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder=""
                        // isValid={touched.firstName && !errors.firstName}
                        isInvalid={touched.firstName && !!errors.firstName}
                        feedback={errors.firstName}
                        required
                      />
                    </div>
                    <div className="col">
                      <FormInput 
                        type="text"
                        label="Last Name"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder=""
                        // isValid={touched.lastName && !errors.lastName}
                        isInvalid={touched.lastName && !!errors.lastName}
                        feedback={errors.lastName}
                        required
                      />
                    </div>
                  </div>
                  <FormInput 
                    type="email"
                    label="Email Address"
                    name="email"
                    value={props.currentUser.email}
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                    // placeholder=""
                    // isValid={touched.email && !errors.email}
                    // isInvalid={!!errors.email}
                    // feedback={errors.email}
                    disabled
                    help="Kept private. Email is linked to your account and can’t be updated. "
                  />

                    <FormInput
                        type="text"
                        label="Bio (optional)"
                        name="bio"
                        value={values.bio}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Add short bio"
                        // isValid={touched.firstName && !errors.firstName}
                        isInvalid={touched.bio && !!errors.bio}
                        feedback={errors.bio}
                        help={`${values.bio?.length}/150 characters`}
                      />
               
                  <FormInput
                        type="text"
                        label="Linked url (optional)"
                        name="linkedinUrl"
                        value={values.linkedinUrl}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Add url for your linked profile"
                        // isValid={touched.firstName && !errors.firstName}
                        isInvalid={touched.linkedinUrl && !!errors.linkedinUrl}
                        feedback={errors.linkedinUrl}
                        help="Link will be displayed on your public profile."
                      />

                  <FormSelect 
                    name="timezone"
                    label="Timezone"
                    placeholder="Western Indonesia Time (GMT+7)"
                    required
                    options={props.currentUser.timezones}
                    plainOptions={true}
                    value={values.timezone}
                    defaultValue={props.currentUser.timezone}
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    isInvalid={touched.timezone && !!errors.timezone}
                    feedback={errors.timezone}
                  />
                  <div class="d-flex justify-content-end align-items-center">
                    <Button 
                      size="md" 
                      type="outline" 
                      text="Cancel"
                      disabled={isSubmitting} 
                      minWidth
                      className="mr-3"
                      onClick={cancelEdit.bind(null, resetForm)}
                    />
                    <Button 
                      size="md" 
                      type="primary" 
                      text={isSubmitting ? "Saving Changes..." : "Save Changes"} 
                      submit 
                      disabled={isSubmitting} 
                      minWidth
                    />
                  </div>

                  {savedChanges && showMessage ? <Alert variant="success" className="mt-3">Your profile changes are saved</Alert> : null}
                  
                </Form>
            )}
          </Formik>
          <div className="profile-settings-section pb-5">
            <h5>Email Notifications</h5>
            <p className="p4">Update your email notification preferences</p>
            <div className="list">
              <div className='d-flex justify-content-between' >
                <p className="p3">Send email about new reponses to my community posts</p>
              <ToggleSwitch
                  isOn= {emailCommunityResponse}
                  id="asj0"
                  onColor="#97e19f"
                  handleChange={handleChangeEmailCommunityResponse}
                />
              </div>
              
              <div className='d-flex justify-content-between' >
                <p className="p3" >Receive email updates about new module related content</p>
                <ToggleSwitch
                  isOn= {emailNewModule}
                  id="asj01"
                  onColor="#97e19f"
                  handleChange={handleChangeEmailModuleChange}
                />
              </div>
              <div className='d-flex justify-content-between' >
                <p className="p3">Occasional community updates and news</p>
                <ToggleSwitch
                  id="asj02"
                  isOn= {emailOccasionalCommunication}
                  onColor="#97e19f"
                  handleChange={handleChangeEmailOccasionalCommunication}
                />
              </div>
            </div>
          </div>

          <div className="profile-settings-section d-flex justify-content-between align-items-center pb-5">
            <div>
              <h5>Password</h5>
              <p className="p4">Update your account password</p>
            </div>
            <Button 
              size="md" 
              type="outline" 
              text="Change Password"
              submit 
              minWidth
              onClick={() => history.push(routes.resetPassword)}
            />
          </div>
          <div className="profile-settings-section pb-5">
            <p className="p4">Need help with your account? Visit <Link to={routes.gettingStartedFaq}>Frequently Asked Questions</Link> or <a href="mailto:info@fptraining.org">Contact Us</a></p>
          </div>
          </div>
        </div>
      </PageContent>
      <PageFooter/>
    </>
    );
  }



const mapStateToProps = state => {
  const currentUser = getLoginState(state);
  return { currentUser };
};

export default connect(
  mapStateToProps,
  { getProfileFetch, loginUser }
)(withRouter(Settings));