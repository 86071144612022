import React, { useEffect, useState, useRef } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {DISCISSIONS_SORT_FILTERS} from "../../constants";
import Navbar from '../../components/Navbar';
import Breadcrumb from '../../components/Breadcrumb';
import PageHeader from '../../components/PageHeader';
import NavbarTabs from '../../components/NavbarTabs';
import PageContent from '../../components/PageContent';
import classNames from 'classnames';
import FaqAccordian from '../../components/FaqAccordian/FaqAccordian';
import FaqEntry from '../../components/FaqEntry';
import TopNavbar from '../../components/TopNavbar';
import LoginPrompt from '../../components/LoginPrompt'
import SearchBar from '../../components/SearchBar';
import FilterDropdown from '../../components/FilterDropdown';
import DiscussionList from '../../components/DiscussionList';
import Button from '../../components/Button';
import DiscussionFilterNav from '../../components/DiscussionFilterNav';
import TopContributors from '../../components/TopContributors';
import CommunityPost from '../../components/CommunityPost';
import PostComment from '../../components/PostComment';
import CommunityWarning from '../../components/CommunityWarning';
import Comments from '../../components/Comments';
import RelatedDiscussions from '../../components/RelatedDiscussions/RelatedDiscussions';
import PageFooter from '../../components/PageFooter/PageFooter';
import { addPost, addComment, addPostRelatedDiscussion } from './ducks/actions';
import { getPostById, getCommentsByIds, getRelatedDiscussions } from './ducks/selectors';
import { getLoginState } from '../Common/ducks/selectors';
import api from '../../adaptors/xhr/api';
import { getProfileFetch } from '../Common/ducks/operations';
import { routes } from '../../routes';
import LoginModal from '../../components/LoginModal';
import ReportAbuseModal from '../../components/ReportAbuseModal';
import CommunityGuidelines from '../../components/CommunityGuidelinesModal';

function Discussion(props) {

  const commentRef = React.createRef();
  const executeScroll = () => commentRef.current.scrollIntoView();
  const [ id, setId ] = useState(null);
  const [ loginShow, setLoginShow ] = useState(false);
  const [ reportabuseShow, setReportabuseShow ] = useState(false);
  const [ guidelinesShow, setGuidelinesShow ] = useState(false);
  const [createpostShow, setCreatePostShow] = useState(false);
  const [ commentboxFocus, setCommentboxFocus ] = useState(false);
  // abuse reporting
  const [ targetType, setTargetType ] = useState(null); 
  const [ targetComment, setTargetComment ] = useState(null);

  // post id from url
  let { slug } = useParams();
  const post = useSelector((state) => getPostById(state, id));
  const relatedDiscussions = useSelector((state) => getRelatedDiscussions(state, id));
  
  // const comments = [];
  const comments = useSelector((state) => getCommentsByIds(state, post?.comments));
  

  /** fetch related discussions */
  const fetchRelatedDiscussions = async (text) => {
    const response = await api.Forum.findDiscussions(text);
    response?.data?.results?.map(async relatedPost => {
      if (id != relatedPost.id) {
        await props.addPost(relatedPost);
        await props.addPostRelatedDiscussion(id, relatedPost.id);
      } 
    })
  }

  /** handles */
  // fetch discussion if not in store
  const fetchPost = async (slug) => {
    const response = await api.Forum.postBySlug(slug);
    setId(response.data.id);
    await props.addPost(response.data);
    // fetchRelatedDiscussions(response.data.title)
  }

  const fetchComments = async (id) => {
    const responseComments = await api.Forum.comments(id);
    responseComments?.data?.results?.map(comment => {
      props.addComment(id, comment);
    })
  }

  const postCommentSuccess = (id, comment) => {
    props.addComment(id, comment);
  }

  const promtLogin = () => {
    setLoginShow(true);
  }

  const reportPost = () => {
    if(props.currentUser.email) {
      setTargetType('post');
      setReportabuseShow(true);
    }
    else 
      promtLogin();
  }

  const reportComment = (commentId) => {
    if(props.currentUser.email) {
      setReportabuseShow(true);
      setTargetComment(commentId);
      setTargetType('comment');
    }
    else
      promtLogin();
  }

  const replyClickHandle = () => {
    // setCommentboxFocus(true);
    if(props.currentUser.email) {
      executeScroll();
    }
    else {
      promtLogin();
    }
  }

   const onContinueGuidelines = () => {
    setGuidelinesShow(false); 
    if(props.currentUser.email) 
      setCreatePostShow(true)
    else
      setLoginShow(true);
  }

const initData = async () => {
  await fetchPost(slug);
  await fetchRelatedDiscussions(post.title);
}
  /** hooks */
  useEffect(() => {
    // props.getProfileFetch();
    initData();
  }, [slug])

  useEffect(async () => {
    if (id != null) {
      await fetchComments(id);
    }
  }, [id])


  return (
  <>
    <TopNavbar />
    <Navbar />
    <Breadcrumb breadcrumbs={["Home", "Community", "Discussion"]} links = {["/", routes.discussions, `${routes.discussions}/${slug}`,]}/>

    <PageContent>
      <div className="row">
        <div className="col-xs-12 col-lg-7">
          <div className="">
            <CommunityPost 
              thread={post} 
              author={post?.author} 
              title={post?.title} 
              body={post?.body} 
              viewsCount={post?.views_count} createdAt={post?.created_at}
              reportAbuse={reportPost}
              reply={replyClickHandle}
              ref={commentRef}
            />
            {props.currentUser.email?
              <PostComment 
              dicussionId={id} 
              onPostSuccess={postCommentSuccess} 
              author={props.currentUser} 
              promtLogin={promtLogin}
              // ref={commentRef}
              // focus={commentboxFocus}
            />
            : <LoginPrompt promtLogin={promtLogin} />
          }
            
            
            <Comments
              count={comments?.length}
              comments={comments} 
              reportAbuse={reportComment} 
              reply={replyClickHandle}
            />
          </div>
        </div>

        <div className="col-xs-12 col-lg-4 offset-lg-1">
          <CommunityWarning className="mb-3" showModal={() => setGuidelinesShow(true)}/>
          <RelatedDiscussions discussions={relatedDiscussions} className="mt-5"/>
        </div>
      </div>
      <LoginModal
        show={loginShow}
        onHide={() => setLoginShow(false)}
      />
      <ReportAbuseModal
        show={reportabuseShow}
        onHide={() => setReportabuseShow(false)}
        targetType={targetType}
        discussionId={id}
        targetCommentId={targetComment}
      />
      <CommunityGuidelines
        show={guidelinesShow}
        onHide={() => setGuidelinesShow(false)}
        onContinue={onContinueGuidelines}
      />
    </PageContent>
    <PageFooter/>
  </>
  );
}

const mapStateToProps = state => {
  const currentUser = getLoginState(state);
  return { currentUser };
};

export default connect(
  mapStateToProps,
  { addPost, addComment, addPostRelatedDiscussion, getProfileFetch }
)(Discussion);