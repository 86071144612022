import React from 'react';
import {
  Link,
} from "react-router-dom";
import CommentsIcon from '../CommentsIcon';
import timeagoFromDate from '../../utils/timeagoFromDate';
import UserThumb from '../UserThumb';
import { routes } from '../../routes';
import MessageIcon from '../../assets/icons/message.svg';
import Button from '../Button/Button';

function PublicActivityList(props) {
  if (props.activities.length) {
  return (
    <ul className="activities-list-group list-group list-group-flush">
      {props.activities?.map(item => {
        return (
          <>
          <li href="#" key={item?.id} className="list-group-item list-group-item-action" aria-current="true">
            <div className="d-flex justify-content-start align-items-start">
              {item.post?.id ? <Link to={`${routes.discussions}/${item.post?.id}`}><h6 className="mb-1">{item.post?.title}</h6></Link>
              : <h6 className="mb-1">Post is removed</h6>
              }
              
            </div>
            <div className="d-flex w-100 justify-content-start align-items-start mt-3">
              <div className="thumbnail h-100 d-flex justify-content-center align-items-start">
                <UserThumb thumbnail={props.userProfile?.profile_picture} firstName={props.userProfile?.first_name} lastName={props.userProfile?.last_name} />
              </div>
              <div className="thumbnail h-100 d-flex justify-content-center align-items-start flex-column" >
                <div className="thumbnail h-100 d-flex justify-content-center align-items-start">
                  <h6>{props.userProfile?.first_name} {props.userProfile?.last_name}</h6>
                  <span className="timestamp-info ml-3">{item?.type === "comment" ? "replied": "posted"} {timeagoFromDate(item.activity_at)}</span>
                </div>
                {/* <p className="p4 mb-1">{item?.comment?.comment}</p> */}
                <p className="p4 mb-1" dangerouslySetInnerHTML={{__html: item?.comment?.comment}}></p>
                {item?.type === "comment" && item?.comment?.comment 
                  || item?.type === "post" && item?.post?.title ? 
                  <div className="post-actions">
                    <span><Button onClick={() => { props.editActivity(item?.type, item) }} text="Edit" minWidth /></span>
                    <span> • </span>
                    <span><Button onClick={() => { props.deleteActivity(item?.type, item) }} text="Delete" minWidth /></span>
                  </div> : 
                  item?.type === "comment" ? <p class="p4 mb-1">{"This comment was removed"}</p> : null }
              </div>
            </div>
          </li>
          </>
        )
      })}
    </ul>
    );
  }
  else {
    return (
      <div className="no-activity-wrapper d-flex justify-content-center align-items-center">
      <div className="no-activity-container">
        <span className="icon d-flex justify-content-center align-items-start mb-2" id="basic-addon1">
          <img src={MessageIcon} className="img-responsive" alt="message icon" />
        </span>
        <p className="p3">No posts or comments</p>
        <p className="p4">Your community posts and discussions will <br/> appear here. <Link to={routes.discussions}>Browse Topics</Link>.</p>
      </div>
      </div>
    )
  }

  
}

export default PublicActivityList;