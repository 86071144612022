import Carousel from 'react-bootstrap/Carousel';
// import Icon from '../../components/Icon';
import icon1 from '../../assets/icons/01.svg';
import icon2 from '../../assets/icons/2.svg';
import icon3 from '../../assets/icons/3.svg';
import icon4 from '../../assets/icons/4.svg';
import icon5 from '../../assets/icons/5.svg';
import icon6 from '../../assets/icons/6.svg';
import Card from '../../components/Card1';


export default function NewsSection(props) {

  return (
    <div className="getting-started-section">
      <div className="container-xl">
        <div className="col-12 col-md-10 mx-auto">
          <div className="header py-4 text-center">
            {/* <h3>Getting started with modules</h3>
            <p className="p2">Short guide to understand module structure </p> */}
          </div>
          <h4>{props.title}</h4>
          <p className="p3" >
            {props.description}
          </p>

          <Carousel variant="dark">
            {props.carouselPhotos?.map(photo => 
            <Carousel.Item>
              <img
                className="d-block img-responsive img-fluid"
                src={photo.file}
                alt={photo.caption}
                width="598"
                height="424"
                style={{
                  margin: 'auto'
                }}
                // className="w-auto"
              />
            </Carousel.Item>
            )}
            
          </Carousel>
          
          <div className="row">
            <div className="col">
              <Card 
                icon={icon1} 
                title="Module - Specific Facilitator’s Guide"
                body="The Module-Specific Facilitator’s Guide provides guidance 
                on how to effectively use the components of that module in the design 
                and delivery of training on that topic."
              />
            </div>

            <div className="col">
              <Card 
                icon={icon2} 
                title="Learning Objectives"
                body="The learning objectives outline what the trainee is expected to be 
                able to do by the end of the training, based on that module. "
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Card 
                icon={icon3} 
                title="Module Session Plan"
                body="The Session plan provides information to the trainer on the 
                session content, timing, trainer and learner activity instructions for demonstration 
                and  practice and materials/resources that the trainer will need to facilitate that session."
              />
            </div>

            <div className="col">
              <Card 
                icon={icon4} 
                title="Presentation Slides"
                body="The PowerPoint presentations include technical information on the topic with basic, advanced, optional slide sets for trainers to use based on learner needs and scope of work. In each module, you may find multiple sets of each type of slide, in order to make downloading of files easier by keeping the size smaller."
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Card 
                icon={icon5} 
                title="Evaluation Tools"
                body="Each module contains tools for evaluating the knowledge and skill of trainees. These include pre and post knowledge assessment questionnaires having objective questions, with instructions for scoring the tests and competency-based skills checklists for assessing the acquired skills during and after the training. It also contains the course evaluation tool."
              />
            </div>

            <div className="col">
              <Card 
                icon={icon6} 
                title="Handouts"
                body="Each module has a set of handouts with specific information, key take away messages and job aids to support learning at their workplace. Handouts should be printed and distributed to the learners."
              />
            </div>

          </div>

          </div>


        </div>
        


      </div>
  );
}