import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  withRouter
} from "react-router-dom";
import { connect } from "react-redux";
import Button from '../Button';
import DownloadIcon from '../../assets/icons/download.svg';
import filenameFromUrl from '../../utils/filenameFromUrl';
import {
  getTrainingModuleCategories, 
  getTrainingModulesByCategory
} from '../../adaptors/xhr/trainingService';
import { addModule, addModuleCategory, setCategoryFilter, setModuleSearch, setSearchFilter } from '../../pages/Training/ducks/actions';
import { getModules, getModuleCategories, getModuleCategoriesByFilter, getModuleCategoryFilter, getModuleSearch, getModuleSearchText } from '../../pages/Training/ducks/selectors';
import Icon from '../../components/Icon';
import UNFPALogo from '../../assets/images/partner-UNFPA.svg';
import USAIDLogo from '../../assets/images/partner-USAID.svg';
import WHOLogo from '../../assets/images/partner-WHO.svg';
import { routes } from '../../routes';
import ContactModal from '../ContactModal';



function PageFooter(props) {
const [contactShow, setContactShow] = useState(false);
const handleContactClick = () => {
  setContactShow(true);
};

  useEffect(() => {
    if(props.moduleCategories?.length == 0) {
      getTrainingModuleCategories()
      .then((allCategory) => {
        if (!props.modules?.length) {
          allCategory.data.results.map((category) => {
            props.addModuleCategory(category)
            getModulesByCategory(category.id)
          })
        }
        
      })
      .catch(error => console.log('Error', error));
    }
    
  }, [])

    // function to store modules by category in store
    function getModulesByCategory(categoryId) {
      getTrainingModulesByCategory(categoryId)
      .then((modules) => {
        modules.data.results.map((module) => {
          props.addModule(module, categoryId)
        })
      })
    }
  return (
    <div className="page-footer">
      <div className="container-xl">
        {props.nonav ? null : (
          <div className="row footer-nav">
            <div className="col-xs-12 col-sm-4">
              {props.moduleCategories && props.moduleCategories.length
                ? props.moduleCategories.map((category, count) => {
                    if (count < Math.ceil(props.moduleCategories.length / 2)) {
                      return (
                        <div className="" key={count}>
                          <div className="nav-section">
                            <p className="c1 d-block">{category.name} </p>
                            {props.modules.map((data) => {
                              if (data.categoryId === category.id)
                                return (
                                  <div key={data.id}>
                                    <Link
                                      className="p4"
                                      to={`${routes.training}/${data.slug}/`}
                                    >
                                      {data.name}
                                    </Link>
                                  </div>
                                );
                              else {
                                return null;
                              }
                            })}
                          </div>
                        </div>
                      );
                    }
                  })
                : null}
            </div>

            <div className="col-xs-12 col-sm-4">
              {props.moduleCategories && props.moduleCategories.length
                ? props.moduleCategories.map((category, count) => {
                    if (
                      count >= Math.ceil(props.moduleCategories.length / 2) &&
                      count <= props.moduleCategories.length
                    ) {
                      return (
                        <div className="" key={count}>
                          <div className="nav-section">
                            <p className="c1 d-block">{category.name} </p>
                            {props.modules.map((data) => {
                              if (data.categoryId === category.id) {
                                return (
                                  <div key={data.id}>
                                    <Link
                                      className="p4"
                                      to={`${routes.training}/${data.slug}/`}
                                    >
                                      {data.name}
                                    </Link>
                                  </div>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </div>
                        </div>
                      );
                    }
                  })
                : null}
            </div>

            <div className="col-xs-12 col-sm-4">
              <div className="nav-section">
                <p className="c1 d-block">Platform</p>
                <Link className="p4" to={routes.about}>
                  About TRP
                </Link>
                <Link className="p4" to={routes.newsAndUpdates}>
                  News and Updates
                </Link>
                <Link className="p4" to={routes.discussions}>
                  Community
                </Link>
                <Link className="p4" to={routes.trainingGuides}>
                  Training Guides
                </Link>
                <Link className="p4" to={routes.gettingStartedFaq}>
                  FAQ
                </Link>
                <a href="#" className="p4" onClick={handleContactClick}>
                  Contact Us
                </a>
                <a className="p4">Twitter</a>
              </div>
            </div>
          </div>
        )}
        <ContactModal show={contactShow} onHide={() => setContactShow(false)} />

        <div className="row col terms">
          <p className="s1">
            The materials in the Training Resource Package for Family Planning
            have been co-developed by a consortium of organizations led by
            USAID, WHO, and UNFPA. This Training Resource Package is made
            possible by the generous support of the American People through the
            United States Agency for International Development (USAID). This
            platform is managed by{" "}
            <b className="s1-b">
              <a href="https://usaidmomentum.org/" style={{ color: "#49545C" }}>
                MOMENTUM Country and Global Leadership
              </a>{" "}
            </b>{" "}
            , under the terms of the Cooperative Agreement #7200AA20CA00002, led
            by Jhpiego and partners. The information provided on this platform
            is not official U.S. government information and does not represent
            the views or positions of USAID or the U.S. government.
          </p>
        </div>

        <div className="row footer-end d-sm-flex justify-content-between align-items-center">
          <div className="col-12 col-sm d-flex justify-content-start align-items-center copyright">
            <p className="s1 mb-0">
              © 2022 The materials as part of this Training Resource Package for
              Family Planning are freely available for adaptation and use with
              attribution in noncommercial contexts.
            </p>
          </div>
          <div className="px-3 justify-content-between align-items-center partner-logos row">
            <div className="col">
              <a href="https://usaidmomentum.org/">
                <Icon icon={USAIDLogo} className="footer-logo img-fluid" />
              </a>
            </div>
            <div className="col">
              <a href="http://www.who.int/en/">
                <Icon icon={WHOLogo} className="footer-logo img-fluid" />
              </a>
            </div>
            <div className="col">
              <a href="http://www.unfpa.org/">
                <Icon icon={UNFPALogo} className="footer-logo img-fluid" id="unfpa-logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


const mapStateToProps = state => {
  const modules = getModules(state);
  const moduleSearchText = getModuleSearchText(state)
  // const moduleSearch = getModuleSearch(state, moduleSearchText)
  const moduleCategories = getModuleCategories(state);
  const moduleCategoryFilter = getModuleCategoryFilter(state);
  const moduleCategoriesFiltered = getModuleCategoriesByFilter(state, moduleCategoryFilter);
  return { modules, moduleCategories, moduleCategoryFilter, moduleCategoriesFiltered };
};

export default connect(
  mapStateToProps,
  { addModule, addModuleCategory, setCategoryFilter, setModuleSearch, setSearchFilter }
)(PageFooter);