import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ArrowIcon from '../../assets/icons/arrow.svg';
import secToMin from '../../utils/secToMin';
import VideoPreview from '../VideoPreview/VideoPreview';

function  VideoBlockSmall(props) {
  const trimVideoTitle = (title) => {
        if(title.length >= 47){
            const newTitle = `${title.slice(0,45)}....`
            return newTitle
        }
        return title
    }
  return (
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey={props.eventKey}>
        <div className="accordion-button d-flex justify-content-between">
          <div>
          <span>{props.title}</span>
          <p className="subtext">{`${props.videoData.length || 0} Videos`}</p>
          </div>
          <div className="d-flex align-items-center">
            <img src={ArrowIcon} className="img-responsive" alt="arrow icon" width="16" height="13.4" />
          </div>
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={props.eventKey}>
        <Card.Body>
          <p className="p3 mx-0">{props.description}</p>
          {/* loop when integrating to api */}
          <div className='row'>
            {props.videoData.map((item) => {
            return (
              <div class="col-sm d-flex video-container">
              <div className="video-thumbnail">
                        <VideoPreview videoData={item} />
                    </div>
                    <div className="video-details">
                      <div className="video-name">{item.video_title}</div>
                      <div className="video-duration">{secToMin(item.duration)}</div>
                    </div> 
                </div>
            )
          })}
            
          </div>
         
          
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}

export default VideoBlockSmall;