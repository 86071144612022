import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  useRouteMatch, 
  Link
} from "react-router-dom";
import { connect } from "react-redux";
import Navbar from '../../components/Navbar';
import TopNavbar from '../../components/TopNavbar';

import PageFooter from '../../components/PageFooter/PageFooter';
import HeroSection from '../../components/HeroSection';
import PartnerLogos from '../../components/PartnerLogos';
import HighlightsSection from '../../components/HighlightsSection';
import NewsSection from '../../components/NewsSection';
import SubscribeSection from '../../components/SubscribeSection';

import api from '../../adaptors/xhr/api';
import { addNews } from './ducks/actions';
import { getNews } from './ducks/selectors';
import { addHomeContent } from '../Common/ducks/actions';
import { getHomeContent } from '../Common/ducks/selectors';
import { HeroSectionMobile } from '../../components/HeroSection/HeroSectionMobile'

function Home(props) {

  const fetchNews = async () => {
    const response = await api.News.list();
    response.data?.results?.map(news => {
      props.addNews(news)
    })
  }

  const fetchContent = async () => {
    const response = await api.Content.homepage();
    if(response.data)  props.addHomeContent(response.data);
  }

  useEffect(() => {
    fetchNews();
    fetchContent();
  }, [])

  return (
    <>
      <TopNavbar/>
      <Navbar />
      <div className="container-xl d-none d-lg-block">
        <HeroSection title={props.homeContent?.title} description={props.homeContent?.description} image={props.homeContent?.illustration}/>
      </div>
      <div className='container-xl'>
        <HeroSectionMobile image={props.homeContent?.illustration} title={props.homeContent?.title} description={props.homeContent?.description} className="d-lg-none" />
      </div>
      <div className="container-xl">
        <div className="home-partner-logos py-4 d-flex justify-content-between align-items-center flex-column flex-sm-row">
          <PartnerLogos/>
          <Link to="/about" className="know-more mr-0 mt-2 mt-sm-0 mr-sm-5">Know More ›</Link>
        </div>
      </div>

      <HighlightsSection cards={props.homeContent?.home_cards}/>
      <NewsSection newsList={props.newsList.slice(0,4)}/>
      <SubscribeSection/>
      <PageFooter nonav/>
      
    </>
    );
  }


const mapStateToProps = state => {
  const newsList = getNews(state);
  const homeContent = getHomeContent(state);
  return { newsList, homeContent };
};

export default connect(
  mapStateToProps,
  { addNews, addHomeContent }
)(Home);